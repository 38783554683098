import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BaseFormItem} from "@app/admin/base/form-item";
import {ActivatedRoute} from "@angular/router";
import { ResponseAdminOpsRole } from '@wearewarp/types/rest-api/admin/user';
import {Utils} from "@services/utils";
import {RoleManager} from "@services/role-manager";
import {Const} from "@const/Const";


@Component({
  selector: '[user-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../app.scss', '../../../dialogs/dialogs.scss']
})
export class UserFilter extends BaseFormItem {
  @Input() txtHintSearch: String;
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public static get declaration(): FormGroupDeclaration {
    return {
      roleIds: {label: 'Role', notAcceptEmpty: true, placeHolder: 'Select from list'},
      clientId: {label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select from list'},
      carrierId: {label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select from list'},
      driverId: {label: 'Driver', notAcceptEmpty: true, placeHolder: 'Select from list'},
      searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
    }
  };

  protected formGroupDeclaration: FormGroupDeclaration = UserFilter.declaration;

  public condition: any = {}
  public searchKeyword: string = null
  public loaded: number = 0
  public page: number = 1
  selectedRoleSearchClient = false;
  selectedRoleSearchCarrier = false;
  allRoles: ResponseAdminOpsRole[] = [];
  allClients = [];
  allCarriers = [];
  allDrivers = [];

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.searchKeyword = p.search
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    });
    let urls = [
      `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`,
      `${Const.APIURI_CARRIERS}/list/all_for_filter`,
      `${Const.APIURI_DRIVERS}/list/all_for_filter`,
      `${Const.APIURI_USERS}/get/ops_roles`,
    ];
    this.api.concurrentGET(urls).subscribe(
      resp => {
        this.allClients = resp[0].data.list_data;
        this.allCarriers = resp[1].data.list_data;
        this.allDrivers = resp[2].data.list_data;
        this.allRoles = resp[3].data.list_data;
      }, err => {
        this.showErr(err);
      }
    )
  }

  doSearch(s) {
    this.searchKeyword = s
    this.page = 1
    this.loadData()
  }

  onRefresh() {
    this.onReload.emit()
    this.loadData()
  }

  loadData() {
    let q = {page: this.page || 1, loaded: Date.now()}
    if (this.searchKeyword) {
      q['search'] = this.searchKeyword
    }
    if (this.condition.clientId && !Utils.isArrayNotEmpty(this.condition.clientId)) {
      delete this.condition.clientId;
    }
    if (this.condition.carrierId && !Utils.isArrayNotEmpty(this.condition.carrierId)) {
      delete this.condition.carrierId;
    }
    if (this.condition.driverId && !Utils.isArrayNotEmpty(this.condition.driverId)) {
      delete this.condition.driverId;
    }
    if (this.condition.roleIds && !Utils.isArrayNotEmpty(this.condition.roleIds)) {
      delete this.condition.roleIds;
    }
    const f = JSON.stringify(this.condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q)
  }

  private additionalFilter = {
    clientId: false,
    carrierId: false,
  }

  shouldShowFilter(key) {
    if (this.additionalFilter[key] === false) {
      return false;
    }
    return true;
  }

  onChange(value, key) {
    this.page = 1;
    if (value) {
      if (key === 'roleIds') {
        let dataSearchRole = value
        this.selectedRoleSearchCarrier = !!dataSearchRole.includes(RoleManager.carrierId);
        this.selectedRoleSearchClient = !!dataSearchRole.includes(RoleManager.clientId) || !!dataSearchRole.includes(RoleManager.customerReadOnlyId);;
        let selectedCarrierSearch = this.condition?.carrierId?.length > 0 ?? false;
        let selectedClientSearch = this.condition?.clientId?.length > 0 ?? false;
        let roleIdToAdd = [];
        if (selectedCarrierSearch) {
          roleIdToAdd.push(RoleManager.carrierId);
        }
        if (selectedClientSearch) {
          roleIdToAdd.push(RoleManager.clientId);
          roleIdToAdd.push(RoleManager.customerReadOnlyId);
        }
        dataSearchRole = [...new Set([...dataSearchRole, ...roleIdToAdd])];
        this.condition[key] = dataSearchRole;
      } else {
        this.condition[key] = value;
      }
    } else {
      delete this.condition[key];
    }

    if (['carrierId', 'clientId'].includes(key)) {
      this.handleSelectCarrierAndClientSearch(key);
    }

    this.loadData();
  }

  private handleSelectCarrierAndClientSearch(key: string) {

    if (this.condition[key] && this.condition[key].length !== 0) {
      if (!this.condition['roleIds']) {
        this.condition['roleIds'] = [RoleManager[key]];
      } else if (!this.condition['roleIds'].includes(RoleManager[key])) {
        this.condition['roleIds'].push(RoleManager[key]);
      }
      if(key == 'clientId') this.condition['roleIds'].push(RoleManager.customerReadOnlyId);
    } else {
      delete this.condition[key];

      let roleToRemove = [];
      if (!this.selectedRoleSearchCarrier && key === 'carrierId') {
        roleToRemove.push(RoleManager.carrierId);
      }
      if (!this.selectedRoleSearchClient && key === 'clientId') {
        roleToRemove.push(RoleManager.clientId);
        roleToRemove.push(RoleManager.customerReadOnlyId);
      }

      if (roleToRemove && this.condition['roleIds']) {
        this.condition['roleIds'] = this.condition['roleIds'].filter(function (role) {
          return roleToRemove.indexOf(role) === -1;
        });
      }
    }
  }

  private updateAdditionalFilter(roleIds: Array<string>) {
    this.additionalFilter.clientId = roleIds.includes(RoleManager.clientId);
    this.additionalFilter.carrierId = roleIds.includes(RoleManager.carrierId);
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword})
    if (Utils.isArrayNotEmpty(this.model.roleIds)) {
      this.updateAdditionalFilter(this.model.roleIds);
    }
    for (let key of Object.keys(this.additionalFilter)) {
      if (this.additionalFilter[key] === false) {
        delete this.model[key];
      }
    }
    super.ngOnInit();
  }
}
